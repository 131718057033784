import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import NewsStyles from "./News.module.css";

const News = (props) => {
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query NewsQuery {
        allWordpressPost {
          edges {
            node {
              title
              slug
              id
              date(formatString: "MMM DD, YYYY")
              content
              excerpt

              categories {
                name
              }
            }
          }
        }
      }
    `
  );

  return (
    <section>
      <div className="news-wrapper">
        <h2>{props.title}</h2>

        {allWordpressPost.edges.map(({ node }) => {
          //   if (props.sidebar === "true") {
          if (props.category === "Sidebar News") {
            if (node.categories) {
              if (node.categories[0].name === "Sidebar News") {
                return (
                  <div>
                    <h3 className={NewsStyles.h3}>
                      <Link to={`/news/${node.slug}`}>{node.title}</Link>
                    </h3>
                    <div className={NewsStyles.date}>{node.date}</div>
                    <div
                      className={NewsStyles.news_content}
                      dangerouslySetInnerHTML={{ __html: node.excerpt }}
                    />
                    <Link to={`/news/${node.slug}`}>Read More</Link>
                    <span className="flaticon-right-arrow"></span>
                  </div>
                );
              }
            }
          } else {
            // if (node.categories[0].name !== "Sidebar News") {
            if (props.category === "Main") {
              if (node.categories) {
                if (node.categories[0].name === "Main") {
                  return (
                    <div>
                      <h3 className={NewsStyles.h3}>
                        <Link to={`/news/${node.slug}`}>{node.title}</Link>
                      </h3>
                      <div className={NewsStyles.date}>{node.date}</div>
                      <div
                        className={NewsStyles.news_content}
                        dangerouslySetInnerHTML={{ __html: node.content }}
                      />
                      <Link to={`/news/${node.slug}`}>Read More</Link>
                      <span className="flaticon-right-arrow"></span>
                    </div>
                  );
                }
              }
            } else {
              // if (node.categories[0].name !== "Sidebar News") {
              if (props.category === "Covid Relief") {
                if (node.categories) {
                  console.log("category", node.categories[0].name);
                  if (node.categories[0].name === "Covid Relief") {
                    return (
                      <div>
                        <h3 className={NewsStyles.h3}>
                          <Link to={`/news/${node.slug}`}>{node.title}</Link>
                        </h3>
                        <div className={NewsStyles.date}>{node.date}</div>
                      </div>
                    );
                  }
                }
              }
            }
          }
        })}
      </div>
    </section>
  );
};

export default News;
