import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import News from "../components/News";
import SimpleSlider from "../components/slick-slider";

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      title
      date
      content
      wordpress_id
    }
  }
`;

const IndexPage = (props, { handleClick }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="bigPadding">
        <div className="sliderhero">
          <SimpleSlider />
        </div>
        <div className="container-header">
          <div className="main-header">
            <div dangerouslySetInnerHTML={{ __html: props.data.wordpressPage.content }}></div>
            <News title="Latest News" category="Main" />
          </div>
          <div className="sidebar">
            <News title="Covid Relief Resources" sidebar="true" category="Covid Relief" />
            <News title="Resources" category="Sidebar News" />
            <a
              className="button sharefile"
              target="_blank"
              href="https://ksga.sharefile.com/Authentication/Login"
            >
              ShareFile Login
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
