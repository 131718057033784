import React, { useState } from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "gatsby-image";

// stateless function component
//https://react-slick.neostack.com/
//https://github.com/akiran/react-slick
const SimpleSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 9500,
    pauseOnHover: false,
    beforeChange: (next, current) => (document.body.className = "loaded slide-change"),

    afterChange: () => (document.body.className = "loaded"),
  };
  /*  
allWordpressPost(sort: { order: ASC, fields: acf___order }) {
    localFile {
                  childImageSharp {
                    fixed(width: 125, height: 125) {
                      ...GatsbyImageSharpFixed
                    }
                    fluid(maxWidth: 2500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
*/
  const { allWordpressWpSlideritems } = useStaticQuery(
    graphql`
      query SliderQuery {
        allWordpressWpSlideritems {
          edges {
            node {
              title
              id
              content
              acf {
                slider_image {
                  localFile {
                    childImageSharp {
                      fixed(width: 500, height: 300) {
                        ...GatsbyImageSharpFixed
                      }
                      fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                display_caption
              }
            }
          }
        }
      }
    `
  );

  const handleButtonClick = () => {
    setPopupStatus("active");
  };

  const [PopupStatus, setPopupStatus] = useState("hidden");

  const handlePopupClose = (event) => {
    if (event.target.className === "mfp-container" || event.target.className === "mfp-close") {
      setPopupStatus("hidden");
    }
    //setPopupStatus("hidden");
    //console.log(event.target.className);
  };

  return (
    <React.Fragment>
      <Slider {...settings}>
        {allWordpressWpSlideritems.edges.map(({ node }) => {
          return (
            <div className="slider__key" key={node.id}>
              <div className="activeWrap">
                {node.acf.slider_image && (
                  <Img
                    className="slider__image"
                    fluid={node.acf.slider_image.localFile.childImageSharp.fluid}
                  />
                )}

                {node.acf.display_caption && <div className="kl-slide-overlay"></div>}
                {node.acf.display_caption && (
                  <div className="slider__captions container">
                    <h2
                      className="caption__title"
                      dangerouslySetInnerHTML={{ __html: node.title }}
                    />

                    <div
                      className="caption__description"
                      dangerouslySetInnerHTML={{
                        __html: node.content,
                      }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </React.Fragment>
  );
};

export default SimpleSlider;
